import React, { useState } from "react"
import { Link } from "gatsby"
import tw, { styled } from "twin.macro"

import ManualMoreLink from "components/manual-more-link"
import { brandRules } from "utils/brand"
import GreenCheckBullet from "images/icons/check-green-bullet.svg"
import PurpleCheckBullet from "images/icons/check-purple-bullet.svg"
import { getProductPath } from "utils/products"

const Styles = styled.div`
  ${tw`relative`}

  &.mobile-only {
    ${tw`l:hidden`}
  }

  border-radius: 8px;

  &.maxi-card {
    ${tw`hidden l:block col-span-3`}

    height: 400px;

    .title {
      top: 38px;
    }

    .text {
      ${tw`absolute text-white text-base leading-none top-24 left-5`}

      line-height: 175%;
      width: 483px;
      list-style: none;

      li {
        ${tw`mt-3 ml-7 pl-3`}

        list-style-image: url(${GreenCheckBullet});
      }

      li > div {
        display: inline;
      }
    }

    .image {
      ${tw`absolute top-8 right-5 overflow-hidden`}

      height: 363px;

      img {
        ${tw`rounded-none`}

        width: 633px;
      }
    }

    &.purple-bullet {
      .text {
        li {
          list-style-image: url(${PurpleCheckBullet});
        }
      }
    }
  }

  .title {
    ${tw`absolute text-white font-display capitalize leading-none`}

    font-size: 23px;
    text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.18);
    left: 17px;
    top: 10px;

    @media (min-width: 640px) {
      font-size: 26px;
      left: 20px;
      top: 17px;
    }
  }

  .image {
    img {
      border-radius: 5px;
    }
  }
`

const OuterBorder = styled(Link)`
  & > div {
    ${tw`h-full bg-cover bg-top`}

    border-radius: 5px;
    background-image: ${(props) => (props.bg ? `url(${props.bg})` : "")};
  }

  @media (min-width: 640px) {
    ${tw`p-1 block overflow-hidden border relative h-full`}

    border-color: rgba(255, 255, 255, 0);
    transition: all 0.2s;
    border-radius: 8px;

    &:hover,
    &.active {
      ${tw`border-yellow-light`}
    }
  }
`

const ProductGroupCard = ({ cardInfo, className = "" }) => {
  const [hovered, setHovered] = useState(false)

  const url = getProductPath(cardInfo.path || cardInfo.linkUrl)

  return (
    <Styles
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      className={className}
    >
      <OuterBorder
        className={`${hovered ? "active" : ""}`}
        to={url}
        bg={cardInfo.icon?.url}
      >
        <div>
          <div className="title">
            <ManualMoreLink active={hovered}>
              <span
                dangerouslySetInnerHTML={{
                  __html: brandRules(cardInfo.headerTitle || cardInfo.title),
                }}
              />
            </ManualMoreLink>
          </div>
          {className.includes("maxi-card") && (
            <div
              className="text"
              dangerouslySetInnerHTML={{ __html: cardInfo.mainContent?.html }}
            ></div>
          )}
          <div className="image">
            <img src={cardInfo.featuredImage.url} />
          </div>
        </div>
      </OuterBorder>
    </Styles>
  )
}

export default ProductGroupCard
