function getProductId(page) {
  return page.path?.split("/").pop() || page.id
}

function getProducts(pages) {
  return pages
}

function getLiteProducts(pages) {
  return pages
}

function getProductPath(path, addTrailingSlash = true) {
  let fullPath = path

  if (!fullPath.endsWith("/") && addTrailingSlash) {
    fullPath += "/"
  }

  return fullPath
}

module.exports = { getProductId, getProducts, getLiteProducts, getProductPath }
