import React, { useState } from "react"
import { graphql } from "gatsby"
// eslint-disable-next-line no-unused-vars
import { PageFragment } from "components/fragments"
import tw, { styled } from "twin.macro"
import "styles/hubspot.css"
import HubspotForm from "react-hubspot-form"

import Layout from "components/layout"
import SEO from "components/seo"
import FeaturedTestimonial from "components/sections/testimonial"
import FeaturedVideo from "components/sections/featured-video"
import DownArrow from "images/icons/arrow-down-grey.svg"
import BasicBackgroundSection from "components/basic-background-section"
import BgWaves from "images/bg/waves.jpg"
import StyledBlock from "components/newsroom/styled-block"
import SlantedSection from "components/sections/slant"
import Demand360Globe from "components/sections/demand360-globe"

import { getSharePreviewUrls } from "utils/share-preview"

const Container = styled.div`
  ${tw`font-body py-16 m:pt-32 pb-10 m:pb-20 l:pb-28 flex flex-wrap items-center justify-center overflow-hidden mx-auto l:max-w-l`}

  max-width: 90%;

  @media (min-width: 1024px) and (max-width: 1200px) {
    max-width: 900px;
  }

  .hs-form {
    & > div:first-child {
      ${tw`pt-0`}
    }

    .form-columns-2 {
      ${tw`flex flex-col`}
    }

    select.hs-input {
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      background-repeat: no-repeat;
      background-position: right 12px top 50%;
      background-size: 20px auto;
      background-image: url(${DownArrow});
    }

    .hs-form-field {
      .hs-field-desc,
      label span {
        ${tw`text-black text-sm`}
      }

      &.hs-fieldtype-text,
      &.hs-fieldtype-select {
        label span {
          ${tw`text-black text-base leading-relaxed font-display`}
        }
      }
    }

    .hs-input,
    select,
    textarea {
      ${tw`bg-white`}

      border: 1px solid #C3C3C3;
      border-radius: 5px;
    }

    .hs-input {
      ${tw`px-2`}
    }

    .hs-submit .actions {
      ${tw`w-full l:w-1/4`}
    }
  }

  ${StyledBlock} {
    &.page-header {
      &.half {
        p,
        h1 {
          ${tw`l:text-left`}
        }
      }

      &.full {
        p {
          ${tw`mt-4 m:mt-7`}
        }
      }
    }
  }

  .highlights {
    ${tw`px-5 l:px-6 divide-black divide-y divide-opacity-10 mt-8 hidden l:block`}

    background: #f7f7f7;
    border-radius: 5px;

    & > div {
      ${tw`flex flex-row space-x-3 py-5 l:py-6`}

      img {
        width: 24px;
        height: 24px;
      }

      p {
        ${tw`text-grey-5 text-sm l:text-base text-left`}

        line-height: 175%;
      }
    }
  }

  .support {
    ${tw`mt-16 m:mt-14 l:mt-8 text-sm l:text-base text-center l:text-left`}

    &.full {
      ${tw`l:text-center mt-10 m:mt-10`}
    }

    a {
      ${tw`underline font-extrabold`}
    }
  }
`

const Features = styled.div`
  ${tw`w-full l:w-1/2 py-16`}
`

const ContactUs = ({ data }) => {
  const { page } = data.gcms

  const [submitted, setSubmitted] = useState(false)

  const bgStack = [
    "linear-gradient(0deg, #FFFFFF, #FFFFFF)",
    "linear-gradient(180deg, #000000 6.09%, rgba(0, 0, 0, 0) 7.68%)",
    `url(${BgWaves})`,
  ]

  return (
    <Layout forceHeaderStuck={true}>
      {!!page && (
        <SEO
          title={page.title}
          description={page.description}
          path={page.path}
          sharePreviewUrls={getSharePreviewUrls(page)}
        />
      )}
      <BasicBackgroundSection bgStack={bgStack}>
        <Container className="text-black">
          <div className="w-full py-8 flex flex-col l:flex-row justify-center">
            {!submitted && (
              <>
                <div className="l:w-1/2 l:pr-12">
                  <StyledBlock
                    className="page-header half"
                    dangerouslySetInnerHTML={{
                      __html: page?.headerContent?.html,
                    }}
                  />
                  <div className="highlights">
                    {page?.extraData?.highlights.map(({ text, icon }, i) => (
                      <div key={i}>
                        <img src={icon} />
                        <p>{text}</p>
                      </div>
                    ))}
                  </div>
                  <div
                    className="support hidden l:block"
                    dangerouslySetInnerHTML={{
                      __html: page?.body?.html,
                    }}
                  />
                </div>

                <div className="l:w-1/2 px-2 mt-16 m:mt-14 l:mt-0">
                  <div style={{ maxWidth: "570px" }} className="mx-auto">
                    <HubspotForm
                      portalId={process.env.GATSBY_HUBSPOT_ID}
                      formId={process.env.GATSBY_HUBSPOT_CONTACT_FORM}
                      loading={<div>Loading...</div>}
                      onFormSubmitted={() => setSubmitted(true)}
                    />
                  </div>

                  <div
                    className="support l:hidden"
                    dangerouslySetInnerHTML={{
                      __html: page?.body?.html,
                    }}
                  />
                </div>
              </>
            )}
            {submitted && (
              <div>
                <StyledBlock className="page-header full">
                  <h1>Thank you!</h1>
                  <p>
                    We have received your enquiry and will be in touch shortly.
                  </p>
                </StyledBlock>
                <div
                  className="support full"
                  dangerouslySetInnerHTML={{
                    __html: page?.body?.html,
                  }}
                />
              </div>
            )}
          </div>
        </Container>

        <SlantedSection>
          <Demand360Globe
            className="pt-24 m:pt-36 l:pt-40 contact-us"
            contactUs={true}
          />
        </SlantedSection>

        {!!page && !!page.featuredTestimonial && (
          <FeaturedTestimonial testimonial={page.featuredTestimonial} />
        )}

        {!!page && !!page.featuredVideo && (
          <FeaturedVideo video={page.featuredVideo} />
        )}
      </BasicBackgroundSection>
    </Layout>
  )
}

export default ContactUs

export const query = graphql`
  query {
    gcms {
      page(where: { path: "/contact" }) {
        ...PageData
      }
    }
  }
`
